<template>
  <!-- 班级成绩  -->
  <div class="class-grade">
    <div class="class-box">
      <el-tabs type="border-card" v-model="tab_pane_index" @tab-click='tabClick()'>
        <el-tab-pane label="成绩数据" name="1">
          <gradeData v-if="tab_pane_index=='1'"></gradeData>
        </el-tab-pane>
        <el-tab-pane label="金币数据" name="2">
          <goldData v-if="tab_pane_index=='2'"></goldData>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
// import $bus
import {
  getStudentLisTemporarytApi, getStudentRoutineList,
  endClassApi,//下课
} from '@/api/teacher'
import gradeData from "@/components/teacher/dataCenter/gradeData.vue"
import goldData from "@/components/teacher/dataCenter/goldData.vue"
export default {
  components: {
    gradeData: gradeData,
    goldData: goldData
  },
  data() {
    return {
      is_socket: 0,
      class_type: 1,//1上课中 0未上课 2已下课
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      // bjid:null,
      search_data: {
        bjmc: null
      },
      tab_pane_index: '1',
      tableData: [],
      params: {},
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      bjid: '',
      bjlx: '',
      bjmc: '',
      // 分组
    }
  },
  created() {
  },
  mounted() {
    this.bjlx = this.$route.query.lx
    this.bjid = this.$route.query.classId
    this.bjmc = this.$route.query.bjmc
    this.class_type = this.$route.query.class_type
    // this.getStudentListApiFun()
  },
  methods: {
    lookStudentGrade(result) {
      this.$router.push({ path: '/dataCenter/studentGread' })
      sessionStorage.setItem('router_stu_name', result.xsmc)
    },
    getStudentListApiFun(data) {
      this.params.currentPage = this.pages.currentPage
      this.params.pageSize = this.pages.pageSize
      this.params.bjid = this.bjid
      this.params.yhid = this.$store.state.userInfo.yhid
      this.params.xm = this.search_data.mc
      data = this.params
      let api = getStudentRoutineList
      if (this.bjlx == 2) {
        api = getStudentLisTemporarytApi
      }
      api(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          res.result.list.forEach((item, index) => {
            item.index = (this.pages.currentPage - 1) * this.pages.pageSize + index + 1
            if (item.xb == 1) {
              item.Gender = '男'
            } else if (item.xb == 2) {
              item.Gender = '女'
            } else if (item.xb == 9) {
              item.Gender = '未选择性别'
            } else {
              item.Gender = '未知'
            }
          })
          this.tableData = res.result.list
        }
      })
    },
    //  -------------------------------------分页器
    handleSizeChange(val) {
      this.pages.currentPage = 1;
      this.pages.pageSize = val;
      this.getStudentListApiFun()
    },

    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getStudentListApiFun()
    },
    // 分页查询学生

    handelSearch() {
      this.pages.currentPage = 1;
      this.getStudentListApiFun()
    },
    //切换tab栏
    tabClick() {
      this.search_data.mc = '';
      this.handelSearch()
    },
    // ----------------
  },

}
</script>
<style lang="scss" scoped>
.class-grade {
  height: 100%;
  position: relative;
}
.stu-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.class-box {
  height: 100%;
  :deep(.el-tabs) {
    height: 100%;
  }
  :deep(.el-tabs__content) {
    // background-color: #eee;
    height: calc(100% - 40px);
  }
  :deep(.el-tab-pane) {
    height: 100%;
    .table {
      height: 100%;
      .tea-pagination {
        margin-top: 10px;
      }
    }
  }
  /deep/.stu-group {
    height: 100%;
  }
}
</style>