<template>
  <!-- 错词列表模块  -->
  <div class="gold-data">
    <div class="tea-search">
      <el-form ref="form" :inline="true" class="demo-form-inline" size="small">
        <el-form-item>
          <div class="search-text" style="width:300px">
            <el-input placeholder="请输入学生姓名或关键字查询" v-model="search_data.xsmc" class="input-with-select">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch()">查 询</el-button>
        </el-form-item>
        <el-form-item style="float:right">
          <el-button icon="el-icon-s-opportunity" type="primary" class="fl tea-btn" @click="rewardCoin()" :disabled="jxStatus=='已结束'">奖励金币</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gold-table">
      <el-table stripe border :data="tableData" height="calc(100% - 40px)">
        <el-table-column type="index" label="序号" align="center" width="50" :index="indexMethod" />
        <el-table-column prop="xsmc" label="姓名" align="center" />
        <el-table-column prop="xjh" label="学籍号" align="center" />
        <el-table-column prop="ccrs" label="性别" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.xb==1"> <i class="el-icon-male" style="color:#67C23A;font-size:18px"></i> 男 </div>
            <div v-else-if="scope.row.xb==2"> <i class="el-icon-female" style="color:#F56C6C;font-size:18px"></i> 女 </div>
            <div v-else>未知</div>
          </template>
        </el-table-column>
        <el-table-column prop="zs" label="金币余额" align="center" />
        <el-table-column label="操作" align="center" width="200">
          <template v-slot="scope">
            <el-button type="warning" class="tea-btn" size="mini" @click="deductCoin(scope.row)" :disabled="jxStatus=='已结束'">扣除金币</el-button>
            <el-button type="primary" class="tea-btn" size="mini" @click="viewLog(scope.row)">查看记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tea-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next" :total="pages.total">
        </el-pagination>
      </div>
    </div>
    <!-- 奖励金币pop -->
    <el-dialog :title="reward_gold_obj.title" v-model="reward_gold_obj.dialogVisible" width='800px' :modal-append-to-body="true" class="tea-diolag coin-diolag1">
      <div class="gold-main">
        <el-form label-width="100px" class="demo-form-inline" :inline="true">
          <el-form-item label="金币：" required>
            <div>
              <el-input-number placeholder="请输入内容" :min="1" v-model="reward_gold_obj.model.sl" style="width:200px" type="number"></el-input-number>
              <el-select v-model="reward_gold_obj.gold_unit" placeholder="请选择" style="width:80px;margin-left:10px">
                <el-option label="个" :value="1"></el-option>
                <el-option label="十" :value="10"></el-option>
                <el-option label="百" :value="100"></el-option>
                <el-option label="千" :value="1000"></el-option>
                <el-option label="万" :value="10000"></el-option>
              </el-select>
            </div>

          </el-form-item>
          <el-form-item label="选择学员：" required>
            <div class="select-box">
              <el-card class="student-list">
                <template #header>
                  <div class="clearfix">
                    <span>待选择学生</span>
                  </div>
                </template>
                <el-checkbox-group v-model="stu_checked_keys" @change="nodeClick">
                  <el-checkbox v-for="item in student_list_tree" :label="item.xsid" :key="item.xsid">{{item.xsxm}}</el-checkbox>
                </el-checkbox-group>
              </el-card>
              <el-card class="student-select">
                <template #header>
                  <div class="clearfix">
                    <span>已选择学生</span>
                  </div>
                </template>
                <el-tag class="el-tag" :key="index" v-for="(item,index) in student_select_list" closable :disable-transitions="false" @close="handleClose(item,index)">
                  {{item.xsmc}}
                </el-tag>
              </el-card>
            </div>
          </el-form-item>
          <el-form-item label="原因：" required>
            <el-input type="textarea" resize='none' :autosize="{ minRows: 2, maxRows: 4}" v-model="reward_gold_obj.model.yy" placeholder="请输入原因" style="width:400px">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="reward_gold_obj.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="rewardGoldSubmit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 扣除金币pop -->
    <el-dialog :title="deduct_gold_obj.title" v-model="deduct_gold_obj.dialogVisible" width='800px' :modal-append-to-body="true" class="tea-diolag coin-diolag2">
      <div class="gold-main">
        <el-form label-width="100px" class="demo-form-inline" :inline="true">
          <el-form-item label="金币：" required>
            <div>
              <el-input-number placeholder="请输入内容" :min="1" v-model="deduct_gold_obj.model.sl" style="width:200px" type="number"></el-input-number>
              <el-select v-model="deduct_gold_obj.gold_unit" placeholder="请选择" style="width:80px;margin-left:10px">
                <el-option label="个" :value="1"></el-option>
                <el-option label="十" :value="10"></el-option>
                <el-option label="百" :value="100"></el-option>
                <el-option label="千" :value="1000"></el-option>
                <el-option label="万" :value="10000"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="原因：" required>
            <el-input type="textarea" resize='none' :autosize="{ minRows: 2, maxRows: 4}" v-model="deduct_gold_obj.model.yy" placeholder="请输入原因" style="width:400px">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deduct_gold_obj.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="deductGoldSubmit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 查看记录pop -->
    <el-dialog :title="view_log_obj.title" v-model="view_log_obj.dialogVisible" width='800px' :modal-append-to-body="true" class="tea-diolag coin-diolag3">
      <div class="coin-diolag3">
        <el-table stripe border :data="view_log_obj.logTableData" height="calc(100% - 40px)">
          <el-table-column type="index" label="序号" align="center" width="50" :index="indexMethod" />
          <el-table-column prop="sl" label="金币记录" align="center" width="100">
            <template v-slot="scope">
              <span style="color:#F56C6C" v-show="scope.row.sl<0">{{scope.row.sl}}</span>
              <span v-show="scope.row.sl>=0">+{{scope.row.sl}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lymc" label="金币来源" align="center">
            <template v-slot="scope">
              <span>{{scope.row.lymc}}。</span>
              <span v-show="scope.row.jcyy">{{'原因：'+scope.row.jcyy}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cjrq" label="日期" align="center" width="150">
            <template v-slot="scope">
              <span>{{new Date(scope.row.cjrq).Format("yyyy-MM-dd")}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="tea-pagination">
          <el-pagination @size-change="logHandleSizeChange" @current-change="logHandleCurrentChange" :current-page="view_log_obj.pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="view_log_obj.pages.pageSize" layout="total, sizes, prev, pager, next" :total="view_log_obj.pages.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  studentGoldAllStudentApi,//获取班级学生
  studentGoldListApi,//金币列表
  studentRewardGoldSaveApi,//奖励金币
  studentDeductGoldSaveApi,//扣除金币
  getCoinsList,//金币明细
} from '@/api/teacher'
export default {
  data() {
    return {
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      bjlx: this.$route.query.lx,
      bjid: this.$route.query.bjid,
      bjmc: this.$route.query.bjmc,
      jxStatus: this.$route.query.jxStatus,
      //   日期和关键字
      search_data: {
        yhid: this.$store.state.userInfo.yhid,
        zhid: this.$store.state.userInfo.zhid,
        bjid: Number(this.$route.query.bjid),
        // bjmc: this.$route.query.bjmc,
        xsmc: null,
      },
      tableData: [],
      //   分页数据
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      //  -------------------------------奖励金币
      // 所有的学生
      student_list_tree: [],
      // checkout key
      stu_checked_keys: [],
      // 已经选择的学生
      student_select_list: [],
      stu_select_list: [],
      reward_gold_obj: {
        dialogVisible: false,
        title: '奖励金币',
        model: {
          sl: 0,
          yy: '',
          xsid: '',
          xsmc: '',
        },
        gold_unit: 1,
      },
      // 扣除金币
      deduct_gold_obj: {
        dialogVisible: false,
        title: '扣除金币',
        model: {
          sl: 0,
          yy: '',
        },
        gold_unit: 1,
      },
      // 查看金币记录
      view_log_obj: {
        dialogVisible: false,
        title: '金币明细',
        model: {},
        pages: {
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
        logTableData: [],
      },
    }
  },
  created() {

  },
  mounted() {
    this.getStuGoldList()
    this.getStudentOptions()
  },
  methods: {
    // 获取临时班级下所有的学生
    getStudentOptions() {
      const data = {
        yhid: this.$store.state.userInfo.yhid,
        zhid: this.$store.state.userInfo.zhid,
        bjid: Number(this.$route.query.bjid),
      }
      studentGoldAllStudentApi(data).then(res => {
        if (res.result) {
          this.student_list_tree = res.result
        }
      })
    },
    // -----------------------------查询列表
    getStuGoldList() {
      const data = this.search_data;
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
      studentGoldListApi(data).then(res => {
        if (res.result) {
          this.tableData = res.result.list;
          this.pages.total = res.result.total
        }
      })
    },
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    handelSearch() {
      this.pages.currentPage = 1;
      this.getStuGoldList()
    },
    //  ---------------------------分页查询
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.handelSearch()
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getStuGoldList()
    },
    // --------------------------------插卡错词
    // 奖励金币
    rewardCoin() {
      this.reward_gold_obj.dialogVisible = true;
      this.reward_gold_obj.model.sl = 1;
      this.reward_gold_obj.gold_unit = 1;
      this.reward_gold_obj.model.yy = '';
      this.student_select_list = [];
      this.stu_checked_keys = [];
      this.getStudentOptions()
    },
    // 点击选框选择学生
    nodeClick() {
      this.Treefilter()
    },
    // 右侧框内删除学生
    handleClose(item, index) {
      const key = this.stu_checked_keys.indexOf(item.xsid)
      this.stu_checked_keys.splice(key, 1)
      this.student_select_list.splice(index, 1);
    },
    //  筛选有几个已经选中
    Treefilter() {
      this.student_select_list = [];
      this.student_list_tree.forEach((item, index) => {
        if (this.stu_checked_keys.indexOf(item.xsid) > -1) {
          this.student_select_list.push({ xsid: item.xsid, xsmc: item.xsxm })
        }
      })
    },
    // 奖励金币的提交
    rewardGoldSubmit() {
      if (this.student_select_list.length == 0) {
        this.$message.warning('请先选择学员')
        return false
      }
      if (this.reward_gold_obj.model.yy == '') {
        this.$message.warning('请填写奖励金币原因')
        return false
      }
      const data = JSON.parse(JSON.stringify(this.reward_gold_obj.model));
      data.sl = data.sl * this.reward_gold_obj.gold_unit;
      data.listStu = this.student_select_list;
      data.bjlx = 1//0常规版 1临时版
      data.bjid = this.bjid;//0常规版 1临时版
      data.cjr = this.yhid;
      data.bjmd = this.bjmc;
      studentRewardGoldSaveApi(data).then(res => {
        if (res.resultDesc == 'SUCCESS') {
          this.$message.success('奖励金币成功')
          this.reward_gold_obj.dialogVisible = false;
          this.getStuGoldList()
        }
      })
    },
    // -----------------------------------------扣除金币
    // 点击扣除金币
    deductCoin(result) {
      this.deduct_gold_obj.dialogVisible = true;
      this.deduct_gold_obj.model.sl = 1;
      this.deduct_gold_obj.gold_unit = 1;
      this.deduct_gold_obj.model.yy = '';
      this.deduct_gold_obj.model.xsmc = result.xsmc;
      this.deduct_gold_obj.model.xsid = result.xsid;
    },
    deductGoldSubmit() {
      if (this.deduct_gold_obj.model.yy == '') {
        this.$message.warning('请填写扣除金币原因')
        return false
      }
      const data = JSON.parse(JSON.stringify(this.deduct_gold_obj.model));
      data.sl = data.sl * this.deduct_gold_obj.gold_unit;
      data.bjlx = 1//0常规版 1临时版
      data.bjid = this.bjid;//0常规版 1临时版
      data.cjr = this.yhid;
      data.bjmd = this.bjmc;
      studentDeductGoldSaveApi(data).then(res => {
        if (res.result) {
          this.$message.success('扣除金币成功')
          this.deduct_gold_obj.dialogVisible = false;
          this.getStuGoldList()
        }
      })
    },
    // --------------------------------------------------查看金币记录
    viewLog(result) {
      this.view_log_obj.model = result;
      this.view_log_obj.dialogVisible = true;
      this.getCoinLogList(result)
    },
    // 获取金币明细数据
    getCoinLogList(result) {
      const data = {
        bjlx: 1,
        bjid: this.bjid,
        bjmc: this.bjmc,
        xsid: this.view_log_obj.model.xsid,
        xsmc: this.view_log_obj.model.xsmc,
        currentPage: this.view_log_obj.pages.currentPage,
        pageSize: this.view_log_obj.pages.pageSize
      }
      getCoinsList(data).then(res => {
        if (res.result) {
          this.view_log_obj.logTableData = res.result.list;
          this.view_log_obj.pages.total = res.result.total;
        }
      })
    },
    scrollLoad() {
    },
    // // 每页条数改变
    logHandleSizeChange(val) {
      this.view_log_obj.pages.pageSize = val;
      this.view_log_obj.pages.currentPage = 1;
      this.getCoinLogList()
    },
    // // 当前页改变
    logHandleCurrentChange(val) {
      this.view_log_obj.pages.currentPage = val;
      this.getCoinLogList()
    },
  }
}
</script>
<style lang="scss" scoped>
.gold-data {
  height: 100%;
  position: relative;
}
.tea-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.gold-table {
  height: calc(100% - 60px);
  .tea-pagination {
    margin-top: 10px;
  }
}
.coin-diolag1 {
  /deep/.el-dialog__body {
    height: 600px;
  }
}
.coin-diolag2 {
  /deep/.el-dialog__body {
    height: 300px;
  }
}
.coin-diolag3 {
  height: 500px;
}

.gold-main {
  height: 100%;
}
.select-box {
  width: 100%;
  display: flex;
  align-items: center;
  /deep/.el-card__header {
    padding: 0px 20px;
  }
  /deep/.el-card__body {
    padding: 10px 20px;
    height: calc(100% - 40px);
    overflow-y: scroll;
  }
  .student-list {
    width: 200px;
    height: 400px;
    border: #eee solid 1px;
    /deep/.el-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  }
  .student-select {
    width: 300px;
    height: 400px;
    border: #eee solid 1px;
    margin-left: 40px;
    .el-tag {
      margin: 0 5px;
    }
  }
}
:deep(.el-dialog__header) {
  background: #5186c2;
  .el-dialog__title {
    color: #fff;
  }
}
:deep(.el-dialog__header .el-dialog__close) {
  color: #fff;
}
:deep(.el-dialog__footer) {
  border-top: 1px solid #eee;
  padding: 15px;
  .el-button.el-button--primary {
    border: 1px solid #5186c2;
    background: #5186c2 !important;
  }
}
.tea-pagination {
  width: 100%;
  text-align: right;
  margin-top: 15px;
}
</style>