<template>
  <!-- 成绩数据模块  -->
  <div class="grade-data">
    <div class="tea-search">
      <el-form ref="form" :inline="true" class="demo-form-inline" size="small">
        <!-- 选择集训场次 -->
        <el-form-item>
          <el-date-picker :disabled-date="dealDisabledDate" v-model="time_list" type="daterange" :clearable='false' range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <div class="search-text" style="width:300px">
            <el-input :placeholder="model_type==2?'请输入课文名称或关键字查询':'请输入学生姓名或关键字查询'" v-model="search_data.xsxm" class="input-with-select">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item class="fr">
          <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch()">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="grade-box">
      <!-- 班级成绩 -->
      <div class="table" v-show="model_type==1">
        <el-table stripe border :data="tableData" height="calc(100% - 20px)">
          <el-table-column type="index" label="序号" align="center" width="50" :index="indexMethod" />
          <el-table-column prop="xsxm" label="姓名" align="center" width="80" />
          <el-table-column prop="xjh" label="学籍号" align="center" width="180" />
          <el-table-column prop="xb" label="性别" align="center" width="80">
            <template v-slot="scope">
              <div v-if="scope.row.xb==1"> <i class="el-icon-male" style="color:#67C23A;font-size:18px"></i> 男 </div>
              <div v-else-if="scope.row.xb==2"> <i class="el-icon-female" style="color:#F56C6C;font-size:18px"></i> 女 </div>
              <div v-else>未知</div>
            </template>
          </el-table-column>
          <el-table-column prop="ljxxscs" label="累计学习时长(学习+测试)" align="center" width="190" />
          <el-table-column prop="xxkwzs" label="学习课文总数" align="center" width="110" />
          <el-table-column prop="threeXjzs" label="三星获得数(篇)" align="center" />
          <el-table-column prop="twoXjzs" label="二星获得数(篇)" align="center" />
          <el-table-column prop="oneXjzs" label="一星获得数(篇)" align="center" />
          <el-table-column prop="zeroXjzs" label="零星获得数(篇)" align="center" />
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <div class="btns">
                <el-button type="text" icon="el-icon-view" @click="lookStudentGrade(scope.row)">成绩详情</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tea-pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-change='nodeClick' :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next" :total="pages.total">
          </el-pagination>
        </div>
      </div>
      <!-- 班级成绩的个人详情 -->
      <div class="stu-table" v-show="model_type==2">
        <el-tree ref="tree" style="width:220px" node-key="keyId" :accordion='true' :current-node-key='current_node_key' @node-click='nodeClick' :props="defaultProps" :highlight-current='true' :load="loadNode" :data="tree_props" lazy>
        </el-tree>
        <!-- 数据展示 -->
        <div class="table-data">
          <el-table stripe border :data="tableData" height="calc(100% - 0px)">
            <!-- <el-table-column type="index" label="序号" align="center" width="50" :index="indexMethod" /> -->
            <el-table-column prop="kwmc" label="课文名称" align="center" />
            <!-- <el-table-column prop="bbmc" label="版本" align="center" /> -->
            <el-table-column prop="jmc" label="单元" align="center" v-if="!current_node_key2" />
            <el-table-column prop="kwxxsj" label="学习课文时长" align="center" />
            <el-table-column prop="kwcsj" label="课文测试时长" align="center" />
            <el-table-column prop="cscj" label="测验成绩" align="center" />
            <el-table-column prop="tzlxcj" label="拓展练习成绩" align="center">
              <template v-slot="scope">
                <span>{{scope.row.tzlxcj==-1?'暂无成绩':scope.row.tzlxcj}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="jclxcj" label="基础练习成绩" align="center">
              <template v-slot="scope">
                <span>{{scope.row.jclxcj==-1?'暂无成绩':scope.row.jclxcj}}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="tea-pagination" style="margin-top:10px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next" :total="pages.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $bus
import {
  classTempStudentGradeListApi,//班级成绩
  // ----------------------------------个人成绩详情
  wordEditionOptionsApi,//单词版本下拉
  wordChapterOptionsApi,//单词章节
  classTempStudentGradeInforApi,//个人成绩详情
} from '@/api/teacher'
export default {
  components: {
  },
  data() {
    return {
      model_type: '1',//1班级成绩 2测试成绩 3小组成绩
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      bjlx: this.$route.query.lx,
      bjid: this.$route.query.bjid,
      bjmc: this.$route.query.bjmc,
      jxid: this.$route.query.jxid,
      //   日期和关键字
      search_data: {
        yhid: this.$store.state.userInfo.yhid,
        zhid: this.$store.state.userInfo.zhid,
        bjid: this.$route.query.bjid,
        bjmc: this.$route.query.bjmc,
        jxid: this.$route.query.jxid,
        xsxm: null,
      },
      time_list: [
        this.$route.query.yxqq, this.$route.query.yxqz,
      ],
      time_section: [this.$route.query.yxqq, this.$route.query.yxqz,],
      tableData: [],
      //   班级成绩
      grade_data: [],
      //   分页数据
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      // ------------------------------学生个人成绩详情
      tree_props: [],
      current_node_key: 1,//
      current_node_key1: null,//章
      current_node_key2: null,//节
      defaultProps: {
        children: 'children',
        label: 'mc',
        isLeaf: function (data, node) {
          if (node.level < 2) {
            return false
          } else {
            return true
          }
        },
      },
      stu_infor: {
        //   xsid: '1224',
        //   xsxm: '陈7',
      },
      stu_pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      stuTableData: [],
    }
  },
  created() {

  },
  mounted() {
    this.model_type = '1'
    this.pages.pageSize = 10;
    this.handelSearch()
    // 获取章下拉
    this.getWordTypeOptions()
  },
  methods: {
    // 日期的禁选
    dealDisabledDate(time) {
      let srart = new Date(this.$utils.getNowTime(0, 0, -1, this.time_section[0])).getTime();
      let now = new Date(this.$utils.getNowTime(0, 0, 0,)).getTime()
      let end = new Date(this.time_section[1]).getTime();
      if (now < end) {
        end = now
      }
      return time < srart || time > end
    },
    //切换tab栏
    tabClick() {
      this.tableData = [];
      this.time_list = [this.$route.query.yxqq, this.$route.query.yxqz,];
      this.search_data.xsxm = null;
      this.pages = {
        currentPage: 1,
        pageSize: 10,
        tatal: 0,
      }
      this.handelSearch()
    },
    testTypeChange() {
      this.tabClick()
    },
    // -----------------------------查询列表
    getGradeList() {
      const data = JSON.parse(JSON.stringify(this.search_data));
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
      let api = classTempStudentGradeListApi;
      if (this.model_type == 1) {
        //   班级成绩
        api = classTempStudentGradeListApi;
        data.startTime = this.time_list[0];
        data.endTime = this.time_list[1];
      } else if (this.model_type == 2) {
        // 学生成绩详情
        api = classTempStudentGradeInforApi
        data.startTime = this.time_list[0];
        data.endTime = this.time_list[1];
        data.xsid = this.stu_infor.xsid;
        data.kwmc = this.search_data.xsxm;
        data.zid = this.current_node_key1;
        data.jid = this.current_node_key2;
        data.jxid = this.jxid;
      }
      api(data).then(res => {
        if (res.result) {
          this.tableData = res.result.list;
          this.pages.total = res.result.total;
          if (!res.result.list) {
            this.tableData = []
          }
        }
      })
    },
    // 时间区间改变
    timeChange() {
      this.handelSearch()
    },
    //  ---------------------------分页查询
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.handelSearch()
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getGradeList()
    },
    // 点击查询
    handelSearch() {
      this.pages.currentPage = 1;
      this.getGradeList()
    },
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    // -------------------------------点击查看学生个人成绩详情跳转
    // 获取单元章
    getWordTypeOptions() {
      const data = {
        bjid: this.bjid,
        yycdid: this.$store.state.yycdid,
      }
      wordEditionOptionsApi(data).then(res => {
        if (res.resultCode == 200) {
          this.tree_props = res.result;
          this.tree_props.forEach(item => {
            item.keyId = item.id + item.mc
          })
          this.tree_props[0].keyId = 1;
        }
      })
    },
    // 点击成绩详情
    lookStudentGrade(result) {
      this.model_type = '2'
      this.stu_infor.xsid = result.xsid;
      this.stu_infor.xsxm = result.xsxm;
      this.current_node_key1 = this.tree_props[0].id;
      this.current_node_key2 = null;
      this.pages.pageSize = 10;
      this.handelSearch()
    },
    nodeClick(result, node) {
      if (node.level == 1) {
        this.current_node_key1 = node.data.id;
        this.current_node_key2 = null;
      } else if (node.level == 2) {
        this.current_node_key2 = node.data.id;
      }
      this.handelSearch()
    },
    isLeaf(data, node) {
      // console.log(data, node)
    },
    loadNode(node, resolve) {
      console.log(node)
      if (node.level == 1) {
        // 点击一级
        const data = {
          bjid: this.bjid,
          yycdid: this.$store.state.yycdid,
          zid: node.data.id
        }
        wordChapterOptionsApi(data).then(res => {
          if (res.resultCode == 200) {
            const arr = res.result;
            resolve(arr)
          } else {
            this.$message.warning(res.resultDesc)
          }
        })
      } else if (node.level == 2) {
        return resolve([])
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.grade-data {
  height: 100%;
  position: relative;
}
.tea-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.model-type {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .tea-btn {
    margin-bottom: 20px;
    &.el-button--primary {
      background-color: #5186c2;
      border-color: #5186c2;
    }
  }
  /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #5186c2 !important;
    border: 1px solid #5186c2;
  }
  /deep/.el-radio-button__inner {
    font-size: 16px;
  }
}
.grade-box {
  height: calc(100% - 80px);
  .stu-table {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    border: #eee solid 1px;
    .table-data {
      width: calc(100% - 150px);
      .el-table--border {
        border-top: none;
      }
    }
    /deep/.el-tabs {
      width: 150px;
      /deep/.el-tabs__content {
        display: none !important;
      }
    }
    /deep/.el-tabs--border-card {
      border: none;
      box-shadow: none;
    }
    /deep/.el-tabs__item:first-child {
      font-size: 16px;
      height: 45px;
      line-height: 45px;
      color: #999;
    }
  }
}
</style>